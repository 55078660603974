
import { FlowStep } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { FlowModule } from '../../store'
import List from './list.vue'

@Component({
  components: {
    List
  },
  name: 'FlowPathPreview'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: any

  @Prop() readonly flowId!: string
  @Prop() readonly steps!: FlowStep[]

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentFlowPathId () {
    return this.$queryArray('flow_path')
  }

  get currentFlowStepId () {
    return this.$queryValue('flow_step')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.diagramId === this.currentDiagram?.id && o.handleId === this.currentFlowHandleId)
  }

  get currentFlowStep () {
    return this.currentFlowStepId ? this.currentFlow?.steps[this.currentFlowStepId] : undefined
  }

  get items () {
    return this.steps.map(o => ({
      active: this.currentFlowPathId.includes(o.id),
      id: o.id,
      text: o.description,
      to: {
        query: this.$setQuery({
          flow_path: {
            ...this.$unionQueryArray(o.id),
            ...this.$removeQueryArray(...this.steps.map(s => s.id).filter(s => s !== o.id))
          }
        })
      }
    }))
  }

  get currentItem () {
    return this.items.find(o => o.active)
  }

  get nextItem () {
    const index = this.currentItem ? this.items.indexOf(this.currentItem) : 0
    return index + 1 < this.items.length ? this.items[index + 1] : this.items[0]
  }

  get types () {
    return [
      {
        active: this.steps.some(o => o.type === 'parallel-path'),
        icon: '$custom-duotone-line-columns',
        id: 'parallel-path',
        subtitle: 'Asynchronous steps, first steps show together',
        title: 'Parallel'
      },
      {
        active: this.steps.some(o => o.type === 'alternate-path'),
        icon: '$$custom-solid-code-merge',
        id: 'alternate-path',
        subtitle: 'Steps happening in series based on a choice',
        title: 'Alternate'
      }
    ]
  }

  get currentType () {
    return this.types.find(o => o.active)
  }

  get subflowId () {
    return this.currentFlowStep?.flowId
  }

  get subflowName () {
    if (this.subflowId) {
      if (this.flowModule.flows[this.subflowId]) {
        return this.flowModule.flows[this.subflowId].name
      } else {
        return 'Removed'
      }
    }
  }

  get flowStepDescription () {
    return this.currentFlowStep?.description ? `Step ${this.currentFlowStep.index + 1}: ${this.currentFlowStep.description}` : undefined
  }

  close () {
    this.menuRef.isActive = false
  }

  goToSubflow () {
    const flow = this.subflowId ? this.flowModule.flows[this.subflowId] : undefined
    const diagram = flow ? this.diagramModule.diagrams[flow.diagramId] : undefined
    const diagramModel = diagram ? this.modelModule.objects[diagram.modelId] : undefined
    if (flow && diagram && diagramModel) {
      this.$router.push({
        name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
        params: this.$params,
        query: this.$setQuery({
          comment: undefined,
          connection: undefined,
          diagram: diagram.handleId,
          flow: flow.handleId,
          flow_parent: this.$unionQueryArray(this.currentFlow?.handleId || ''),
          flow_path: undefined,
          flow_step: undefined,
          model: diagramModel.handleId,
          object: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      })
    }
  }
}
