
import { FlowStep, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Draggable from 'vuedraggable'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import NameEdit from './name-edit.vue'
import FlowPathOptionsMenu from './options-menu.vue'

@Component({
  components: {
    Draggable,
    FlowPathOptionsMenu,
    NameEdit
  },
  name: 'FlowPathList'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType
  @Prop() readonly flowId!: string
  @Prop() readonly steps!: FlowStep[]

  optionsMenuItemId: string | null = null
  hoverStepId: string | null = null
  editingStepId: string | null = null

  get currentFlowPathId () {
    return this.$queryArray('flow_path')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get flowPathsLimitReached () {
    return !this.currentOrganizationLimits.flowPaths
  }

  get type () {
    return this.steps.some(o => o.type === 'alternate-path') ? 'alternate-path' : 'parallel-path'
  }

  get items () {
    return this.steps.map(o => ({
      active: this.currentFlowPathId.includes(o.id),
      id: o.id,
      index: o.index,
      name: o.description,
      to: {
        query: this.$setQuery({
          flow_path: {
            ...this.$unionQueryArray(o.id),
            ...this.$removeQueryArray(...this.steps.map(s => s.id).filter(s => s !== o.id))
          }
        })
      }
    }))
  }

  get currentItem () {
    return this.items.find(o => o.active)
  }
}
