
import { FormController } from '@icepanel/app-form'
import { Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'

import { FlowModule } from '../../store'

@Component({
  name: 'PathNameEdit'
})
export default class extends Vue {
  flowModule = getModule(FlowModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)

  @Prop() readonly flowId!: string
  @Prop() readonly stepId!: string

  get flow () {
    return this.flowModule.flows[this.flowId]
  }

  get step () {
    return this.flow.steps[this.stepId]
  }

  formController = new FormController({
    initialModel: {
      name: ''
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      if (this.step.description && this.step.description === model.name) {
        return
      }

      const revertTasks: Task[] = [{
        id: this.flow.id,
        props: {
          steps: {
            $update: {
              [this.step.id]: {
                description: this.step.description
              }
            }
          }
        },
        type: 'flow-update'
      }]

      const { flow, flowUpdate } = this.flowModule.generateFlowCommit(this.flow.id, {
        steps: {
          $update: {
            [this.step.id]: {
              description: model.name.trim() || 'Path'
            }
          }
        }
      })
      this.flowModule.setFlowVersion(flow)
      this.editorModule.addToTaskQueue({
        func: () => this.flowModule.flowUpdate({
          flowId: this.flow.id,
          landscapeId: this.flow.landscapeId,
          props: flowUpdate,
          versionId: this.flow.versionId
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: this.flow.id,
          props: flowUpdate,
          type: 'flow-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })
    }
    this.formController.on('success', () => {
      this.$emit('success')
    })

    this.formController.setInitialModel({
      name: this.step.description
    })
    this.formController.resetModel()
  }
}
