
import { FlowStep, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import List from './list.vue'

@Component({
  components: {
    List
  },
  name: 'FlowPath'
})
export default class extends Vue {
  @Ref() readonly menuRef!: any
  @Ref() readonly pathListRef!: List

  @Prop() readonly permission!: PermissionType
  @Prop() readonly flowId!: string
  @Prop() readonly steps!: FlowStep[]

  get currentFlowPathId () {
    return this.$queryArray('flow_path')
  }

  get items () {
    return this.steps.map(o => ({
      active: this.currentFlowPathId.includes(o.id),
      id: o.id,
      text: o.description,
      to: {
        query: this.$setQuery({
          flow_path: {
            ...this.$unionQueryArray(o.id),
            ...this.$removeQueryArray(...this.steps.map(s => s.id).filter(s => s !== o.id))
          }
        })
      }
    }))
  }

  get currentItem () {
    return this.items.find(o => o.active)
  }

  get nextItem () {
    const index = this.currentItem ? this.items.indexOf(this.currentItem) : 0
    return index + 1 < this.items.length ? this.items[index + 1] : this.items[0]
  }

  get types () {
    return [
      {
        active: this.steps.some(o => o.type === 'parallel-path'),
        click: () => {
          this.$emit('update-path-type', 'parallel-path')
        },
        icon: '$custom-duotone-line-columns',
        id: 'parallel-path',
        subtitle: 'Asynchronous steps, first steps show together',
        title: 'Parallel path'
      },
      {
        active: this.steps.some(o => o.type === 'alternate-path'),
        click: () => {
          this.$emit('update-path-type', 'alternate-path')
        },
        icon: '$$custom-solid-code-merge',
        id: 'alternate-path',
        subtitle: 'Steps happening in series based on a choice',
        title: 'Alternate path'
      }
    ]
  }

  get currentType () {
    return this.types.find(o => o.active)
  }

  close () {
    this.menuRef.isActive = false
  }
}
