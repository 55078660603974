
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { FlowModule } from '../../store'

@Component({
  name: 'FlowPathOptionsMenu'
})
export default class extends Vue {
  flowModule = getModule(FlowModule, this.$store)

  @Prop() readonly menuProps?: any
  @Prop() readonly flowId!: string
  @Prop() readonly stepId!: string

  get flow () {
    return this.flowModule.flows[this.flowId]
  }

  get step () {
    return this.flow.steps[this.stepId]
  }
}
